import React, {useState} from 'react'
import './App.css'
import styled from 'styled-components'
import {toLower} from 'ramda'
import Download from '@axetroy/react-download'

const Field = ({label, ...props}) => (
  <div>
    <label>{label}</label>
    <p>
      <input {...props} />
    </p>
  </div>
)

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`

const H1 = styled.h1`
  margin: 0;
  margin: 0;
  color: #534b4b;
  font-family: Catamaran, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  line-height: 1.5;
`

const Bubble = styled.span`
  margin: 0;
  color: #ffd910;
  font-family: Catamaran, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
`

const PositionSpan = styled.span`
  margin: 0;
  color: #534b4b;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.9px;
  line-height: 1.5;
`

const PhoneNumber = styled.p`
  margin: 0;
  color: #a3a4a9;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.4px;
  font-size: 12px;
  line-height: 1.5;
`

const PhoneNumberLink = styled.a`
  color: #a3a4a9;
`

const Td = styled.td`
  width: 25px;
`

const EmptyTd = styled.td`
  text-decoration: none;
  color: #a3a4a9;
`

const EmailLink = styled.a`
  color: #a3a4a9;
`

const ShipprLink = styled.a`
  color: #6060fa;
  text-decoration: none;
`

const Paragraph = styled.p`
  margin: 2rem 0;
`

const Container = styled.div`
  margin: 1rem;
`

const Code = styled.p`
  border: 1px solid black;
  padding: 1rem;
`

const Signature = ({name, position, phoneNumber, email}) => (
  <Table cellPadding="0" cellSpacing="0">
    <tr>
      <td rowSpan="4" width="50">
        <a href="https://www.shippr.io">
          <img src="https://signatures.shippr.io/shippr.png" alt="Shippr" />
        </a>
      </td>
      <Td rowSpan="4" />
    </tr>
    <tr>
      <td>
        <H1>
          {name.toUpperCase()}
          <Bubble> • </Bubble>
          <PositionSpan>{position}</PositionSpan>
        </H1>
      </td>
    </tr>
    <tr>
      <td>
        <PhoneNumber>
          <PhoneNumberLink href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </PhoneNumberLink>

          <Bubble> / </Bubble>
          <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
        </PhoneNumber>
      </td>

      <EmptyTd />
    </tr>
    <tr>
      <td>
        <PhoneNumber>
          <ShipprLink href="https://www.shippr.io">shippr.io</ShipprLink>
        </PhoneNumber>
      </td>
    </tr>
  </Table>
)

const getMail = (name) => {
  console.log(name)
  const [firstname, lastname] = toLower(name).split(' ')
  console.log(firstname)
  console.log(lastname)
  return `${firstname}.${lastname}@shippr.be`
}

const App = () => {
  const [name, updateName] = useState('Boo Ledog')
  const [position, updatePosition] = useState('HAPINESS MANAGER')
  const [phoneNumber, updateNumber] = useState('+32 (0) 423 18 56 32')
  const [email, updateEmail] = useState(getMail(name))
  const [includeFbPixel, setIncludeFbPixel] = useState(false)
  return (
    <Container>
      <Field
        value={name}
        label="Prénom nom :"
        onChange={({target: {value}}) => {
          updateName(value)
          updateEmail(getMail(value))
        }}
      />
      <Field
        value={position}
        label="Poste :"
        onChange={({target: {value}}) => updatePosition(value)}
      />
      <Field
        value={phoneNumber}
        label="Numéro de téléphone :"
        onChange={({target: {value}}) => updateNumber(value)}
      />
      <Field
        value={email}
        label="Email :"
        onChange={({target: {value}}) => updateEmail(value)}
      />

      <div>
        <label>Sales:</label>
        <p>
          <input
            type="checkbox"
            onChange={(event) => setIncludeFbPixel(event.target.checked)}
            checked={includeFbPixel}
          />
          Include FB Pixel
        </p>
      </div>
      <hr />
      <Signature {...{name, phoneNumber, email, position}} />
      <hr />

      <p>
        Appuie sur le boutton ci-dessous pour télécharger la signature, ouvre la
        dans safari (très important) puis fais CMD+A CMD+C et colles le dans la
        signature gmail
      </p>
      <Download
        file={'signature.html'}
        content={`
          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
          <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <title>${`Signature ${name}`}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <link href='https://fonts.googleapis.com/css?family=Catamaran:800' rel='stylesheet' type='text/css' />
          </head>
          
          <body style="margin: 0; padding:">
          
          
            <table style="border-collapse: collapse; border-spacing: 0;" cellpadding="0" cellspacing="0">
          
              <tr>
                <td rowspan="4" width="50">
                  <a href="https://www.shippr.io">
                    <img src="https://signatures.shippr.io/shippr.png" alt="Shippr" width="117px" />
                     ${
                       includeFbPixel
                         ? `<img
              src="https://www.facebook.com/tr?id=5256248961067035&ev=PageView&noscript=1"
              />`
                         : ''
                     }
                  </a>
                </td>
                <td rowspan="4" style="width: 25px;"></td>
              </tr>
              <tr>
                <td>
                  <h1 style="margin: 0;margin: 0;color:#534b4b; font-family: Catamaran, Arial, Helvetica, sans-serif; font-weight: bold; font-size: 12px; text-transform: uppercase; letter-spacing: 2.4px; line-height: 1.5;">
          
                    ${name}
          
                    <span style="margin: 0;color:#ffd910; font-family: Catamaran, Arial, Helvetica, sans-serif; font-weight: bold; font-size: 12px; line-height: 1;">•</span>
          
                    <span style="margin: 0;color:#534b4b; font-family: Arial, Helvetica, sans-serif; font-size: 12px; text-transform:none; font-weight: normal; letter-spacing: 0.9px; line-height: 1.5;">
          
                    ${position} </span>
          
                  </h1>
                </td>
              </tr>
              <tr>
                <td><p style="margin: 0;color:#a3a4a9; font-family: Arial, Helvetica, sans-serif; letter-spacing: 0.4px; font-size: 12px; line-height: 1.5;"><a href="tel:${phoneNumber}" style="color:#a3a4a9; text-decoration: none;">
          
                  ${phoneNumber}</a>
          
                  <span style="margin: 0; color:#ffd910; font-family: Catamaran, Arial, Helvetica, sans-serif; font-weight: bold; font-size: 12px;">/</span> <a href="mailto:${email}" style="text-decoration:none; color:#a3a4a9;">
          
                  ${email}</a>
          
                </p>  </td>
          
                <td style="font-size: 0; line-height: 1.5">
          
                </td>
              </tr>
              <tr>
                <td><p style="margin: 0;color:#a3a4a9; font-family: Arial, Helvetica, sans-serif;letter-spacing: 0.4px; font-weight:bold; font-size: 14px; line-height: 1.5;"><a href="https://www.shippr.io" style="color:#6060fa; text-decoration: none;">shippr.io</p></td>
              </tr>
          
            </table>
          
          </body>
          
          </html>
         `}
      >
        <button type={'button'}>Download signature</button>
      </Download>
    </Container>
  )
}

export default App
